import { Link, useLocation } from "react-router-dom";
import { MENUS, MenuInterface } from "../../config/menus";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import CheckRole from "../../utils/CheckRole";
import { BiChevronDown } from "react-icons/bi";

function SideNav({ visible }: { visible: boolean }) {
  const location = useLocation();
  const pathname = location.pathname.replace("/", "");
  const { t } = useTranslation();

  const ItemMenu = ({ menu }: { menu: MenuInterface }) => {
    const [show, setShow] = useState(pathname === menu.pathname);
    if (menu.subMenus) {
      return (
        <li
          className={`${pathname.includes(menu.pathname) ? "mm-active" : ""}`}
        >
          <button
            className={`hover:cursor-pointer has-arrow`}
            onClick={() => setShow(!show)}
          >
            <menu.icon />
            <span className={"ml-3"}>
              <span>{t(menu.title)}</span>
              <BiChevronDown className="float-right" />
            </span>
          </button>
          <ul className={`sub-menu ${show ? "mm-show" : "mm-collapse"}`}>
            {menu.subMenus.map((subMenu: MenuInterface, index: number) => {
              return (
                CheckRole(subMenu.roles) && (
                  <li key={"sub-menu" + index}>
                    <Link to={subMenu.link}>
                      <subMenu.icon />
                      <span className={"ml-3"}>{t(subMenu.title)}</span>
                    </Link>
                  </li>
                )
              );
            })}
          </ul>
        </li>
      );
    } else {
      return (
        <li
          className={`${pathname.includes(menu.pathname) ? "mm-active" : ""}`}
        >
          <Link to={menu.link}>
            <menu.icon />
            <span className={"ml-3"}>{t(menu.title)}</span>
          </Link>
        </li>
      );
    }
  };

  return (
    <div className={`sidenav ${!visible ? "hidden" : ""}`}>
      <div className="vertical-menu">
        <div data-simplebar className="h-100">
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              {MENUS.map((menu: MenuInterface, index: number) => {
                return (
                  CheckRole(menu.roles) && (
                    <ItemMenu menu={menu} key={"menu-" + index} />
                  )
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideNav;
