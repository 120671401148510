import { useState } from "react";
import { FIRST_PAGE_VALUE, FormActionType, PER_PAGE_VALUE } from "../config/global";

export default function useHeaderFunctionPage({ baseApiUrl, title }: { baseApiUrl: string, title: string }) {
    const [pagination, setPagination] = useState({ page: FIRST_PAGE_VALUE, perPage: PER_PAGE_VALUE });
    const [openModal, setOpenModal] = useState(false);
    const [currentElement, setCurrentElement] = useState<any>(0);
    const [titleForm, setTitleForm] = useState("");
    const [actionForm, setActionForm] = useState<FormActionType>(FormActionType.ADD);
    const queryKey = [`${baseApiUrl}s`, pagination.page, pagination.perPage];

    return {
        pagination, setPagination,
        openModal, setOpenModal,
        currentElement, setCurrentElement,
        titleForm, setTitleForm,
        actionForm, setActionForm,
        baseApiUrl,
        title,
        queryKey
    };
}