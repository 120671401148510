import Cookies from "js-cookie";
import { CRYPTO_SECRET, LangType, LayoutType } from "../config/global";
import secureLocalStorage from "react-secure-storage";
import moment from "moment";
import crypto from "crypto-js";

//Storage Keys
const auth_key = "auth";
const layout_key = "layout";
const lang_key = "lang";

//Get the current language
export const getStorageLang = (): LangType => {
  if (secureLocalStorage.getItem(lang_key)) {
    return secureLocalStorage.getItem(lang_key) === LangType.fr
      ? LangType.fr
      : LangType.en;
  } else {
    return LangType.fr;
  }
};

//Add User in Storage
const addStorageUser = (user: any, expireDate: string) => {
  const encryptStorageUser = crypto.AES.encrypt(
    JSON.stringify(user),
    CRYPTO_SECRET
  ).toString();
  Cookies.set(auth_key, encryptStorageUser, {
    expires: moment(expireDate).diff(moment(), "days", true),
    secure: true,
    sameSite: "none",
  });
};

//Get User stored
const getStorageUser = () => {
  if (Cookies.get(auth_key) !== undefined && Cookies.get(auth_key) !== null) {
    const decryptStorageUser = crypto.AES.decrypt(
      "" + Cookies.get(auth_key),
      CRYPTO_SECRET
    ).toString(crypto.enc.Utf8);
    return JSON.parse(decryptStorageUser);
  }
  return null;
};

//Get the layout
const getStorageLayout = (): LayoutType => {
  if (secureLocalStorage.getItem(layout_key)) {
    return secureLocalStorage.getItem(layout_key) === LayoutType.horizontal
      ? LayoutType.horizontal
      : LayoutType.vertical;
  } else {
    return LayoutType.horizontal;
  }
};

//Set the layout
const setStorageLayout = (layout: LayoutType) => {
  secureLocalStorage.setItem(layout_key, layout);
};

//Set the lang
const setStorageLang = (layout: LangType) => {
  secureLocalStorage.setItem(lang_key, layout);
};

//Remove User in Storage
const removeStorageUser = () => {
  Cookies.remove(auth_key);
};

export default function useStorage() {
  return {
    addStorageUser,
    getStorageUser,
    removeStorageUser,
    getStorageLayout,
    setStorageLayout,
    getStorageLang,
    setStorageLang,
  };
}
